import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarToggled: false,
  toast: {
    show: false,
    type: 'custom',
    message: 'Message',
    errors: [],
  },
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebarToggled = action.payload;
    },
    showToast: (state, action) => {
      state.toast = action.payload;
    },
  },
});

export const { toggleSidebar, showToast } = componentsSlice.actions;
export default componentsSlice.reducer;
